import '/assets/styles/sections/home-featured-categories.scss';
import PropTypes from 'prop-types';
import { Fragment } from 'react'

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder';
import SectionTitle from '/views/partials/section-title'

const HomeFeaturedCategories = ({ data }) => {
	const categories = data && data.length > 0 ? data : [false, false, false, false, false, false, false, false];
	return (
		<section className="section home-featuredcategories">
			<div className="featuredcategories-wrap wrapper">
				<SectionTitle>Popüler Kategoriler</SectionTitle>
				<div className="featuredcategories-list">
					{categories.map((category, nth) => (
						<Fragment key={nth}>
							{category ?
								<Link
									key={nth}
									href="category"
									params={{ slug: category.slug, id: category.id }}
									className="list-category">
									<div className="category-imagewrap">
										<Img
											className="category-image"
											src={category.list_image}
											title={category.name} />
									</div>
									<strong className="category-title">{category.name}</strong>
								</Link>
								:
								<span
									key={nth}
									className="list-category">
									<div className="category-imagewrap">
										<Placeholder className="category-image" />
									</div>
									<Placeholder className="category-title" />
								</span>
							}
						</Fragment>
					))}
				</div>
			</div>
		</section>
	)
}

HomeFeaturedCategories.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false])]),
}

export default HomeFeaturedCategories